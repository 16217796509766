
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.fa-btn{margin-right:10px;}
.sortable-drag {background: #ddd;}
.fa-sort{cursor:pointer}